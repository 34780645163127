

import React from 'react'

export default () =>  
(<svg id="cloud" className='cloud' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 360">
   <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop-HD" transform="translate(-854.000000, -315.000000)">
            <g id="Group" transform="translate(854.000000, 315.000000)">
                <path d="M396,155 L405,155 C452,155 490,193 490,240 C490,286 452,325 405,325 L109,325 C62,325 24,286 24,240 C24,202 50,168 87,158 C81,90 134,32 202,32 C251,32 294,63 310,107 C346,104 379,123 396,155 Z" id="Path" fill-opacity="0.0525841346" fill="#A8A8A8"></path>
                <g id="1" transform="translate(19.000000, 183.000000)" fill="#94AEFB">
                    <circle id="Oval-Copy-196" cx="5" cy="39" r="5"></circle>
                    <circle id="Oval-Copy-217" cx="22" cy="5" r="5"></circle>
                    <circle id="Oval-Copy-239" cx="17" cy="22" r="5"></circle>
                    <circle id="Oval-Copy-283" cx="5" cy="58" r="3"></circle>
                    <circle id="Oval-Copy-327" cx="17" cy="90" r="5"></circle>
                    <circle id="Oval-Copy-349" cx="7" cy="75" r="5"></circle>
                    <circle id="Oval-Copy-371" cx="25" cy="109" r="5"></circle>
                    <circle id="Oval-Copy-370" cx="41" cy="124" r="5"></circle>
                    <circle id="Oval-Copy-391" cx="63" cy="137" r="5"></circle>
                </g>
                <g id="2" transform="translate(31.000000, 183.000000)">
                    <circle id="Oval-Copy-195" fill="#94AEFB" cx="13" cy="56" r="5"></circle>
                    <circle id="Oval-Copy-197" fill="#94AEFB" cx="5" cy="56" r="5"></circle>
                    <circle id="Oval-Copy-216" fill="#94AEFB" cx="29" cy="5" r="5"></circle>
                    <circle id="Oval-Copy-238" fill="#94AEFB" cx="29" cy="22" r="5"></circle>
                    <circle id="Oval-Copy-261" fill="#94AEFB" cx="8" cy="37" r="3"></circle>
                    <circle id="Oval-Copy-260" fill="#0244FB" cx="29" cy="39" r="5"></circle>
                    <circle id="Oval-Copy-282" fill="#0244FB" cx="29" cy="56" r="5"></circle>
                    <circle id="Oval-Copy-305" fill="#94AEFB" cx="7" cy="73" r="3"></circle>
                    <circle id="Oval-Copy-304" fill="#0244FB" cx="29" cy="73" r="5"></circle>
                    <circle id="Oval-Copy-326" fill="#0244FB" cx="29" cy="90" r="5"></circle>
                    <circle id="Oval-Copy-348" fill="#94AEFB" cx="29" cy="107" r="5"></circle>
                    <circle id="Oval-Copy-393" fill="#94AEFB" cx="19" cy="88" r="5"></circle>
                </g>
                <g id="3" transform="translate(55.000000, 96.000000)">
                    <circle id="Oval-Copy-104" fill="#94AEFB" cx="53" cy="7" r="5"></circle>
                    <circle id="Oval-Copy-127" fill="#94AEFB" cx="31" cy="24" r="5"></circle>
                    <circle id="Oval-Copy-128" fill="#94AEFB" cx="41" cy="5" r="5"></circle>
                    <circle id="Oval-Copy-126" fill="#0244FB" cx="53" cy="24" r="5"></circle>
                    <circle id="Oval-Copy-149" fill="#94AEFB" cx="29" cy="41" r="5"></circle>
                    <circle id="Oval-Copy-148" fill="#94AEFB" cx="53" cy="41" r="5"></circle>
                    <circle id="Oval-Copy-171" fill="#94AEFB" cx="29" cy="58" r="5"></circle>
                    <circle id="Oval-Copy-170" fill="#94AEFB" cx="53" cy="58" r="5"></circle>
                    <circle id="Oval-Copy-194" fill="#94AEFB" cx="5" cy="75" r="5"></circle>
                    <circle id="Oval-Copy-193" fill="#94AEFB" cx="29" cy="75" r="5"></circle>
                    <circle id="Oval-Copy-192" fill="#94AEFB" cx="53" cy="75" r="5"></circle>
                    <circle id="Oval-Copy-215" fill="#94AEFB" cx="29" cy="92" r="5"></circle>
                    <circle id="Oval-Copy-214" fill="#94AEFB" cx="53" cy="92" r="5"></circle>
                    <circle id="Oval-Copy-237" fill="#94AEFB" cx="29" cy="109" r="5"></circle>
                    <circle id="Oval-Copy-236" fill="#94AEFB" cx="53" cy="109" r="5"></circle>
                    <circle id="Oval-Copy-259" fill="#0244FB" cx="29" cy="126" r="5"></circle>
                    <circle id="Oval-Copy-258" fill="#94AEFB" cx="53" cy="126" r="5"></circle>
                    <circle id="Oval-Copy-281" fill="#0244FB" cx="29" cy="143" r="5"></circle>
                    <circle id="Oval-Copy-280" fill="#94AEFB" cx="53" cy="143" r="5"></circle>
                    <circle id="Oval-Copy-303" fill="#94AEFB" cx="29" cy="160" r="5"></circle>
                    <circle id="Oval-Copy-302" fill="#94AEFB" cx="53" cy="160" r="5"></circle>
                    <circle id="Oval-Copy-325" fill="#94AEFB" cx="29" cy="177" r="5"></circle>
                    <circle id="Oval-Copy-324" fill="#94AEFB" cx="53" cy="177" r="5"></circle>
                    <circle id="Oval-Copy-347" fill="#94AEFB" cx="29" cy="194" r="5"></circle>
                    <circle id="Oval-Copy-346" fill="#94AEFB" cx="53" cy="194" r="5"></circle>
                    <circle id="Oval-Copy-369" fill="#94AEFB" cx="29" cy="211" r="5"></circle>
                    <circle id="Oval-Copy-368" fill="#94AEFB" cx="53" cy="211" r="5"></circle>
                    <circle id="Oval-Copy-390" fill="#94AEFB" cx="53" cy="228" r="5"></circle>
                </g>
                <g id="4" transform="translate(103.000000, 42.000000)">
                    <circle id="Oval-Copy-28" fill="#94AEFB" cx="29" cy="10" r="5"></circle>
                    <circle id="Oval-Copy-7" fill="#94AEFB" cx="53" cy="5" r="5"></circle>
                    <circle id="Oval-Copy-29" fill="#94AEFB" cx="53" cy="10" r="5"></circle>
                    <circle id="Oval-Copy-60" fill="#94AEFB" cx="8" cy="28" r="5"></circle>
                    <circle id="Oval-Copy-59" fill="#94AEFB" cx="29" cy="27" r="5"></circle>
                    <circle id="Oval-Copy-58" fill="#94AEFB" cx="53" cy="27" r="5"></circle>
                    <circle id="Oval-Copy-82" fill="#94AEFB" cx="5" cy="44" r="5"></circle>
                    <circle id="Oval-Copy-81" fill="#94AEFB" cx="29" cy="44" r="5"></circle>
                    <circle id="Oval-Copy-80" fill="#94AEFB" cx="53" cy="44" r="5"></circle>
                    <circle id="Oval-Copy-103" fill="#94AEFB" cx="29" cy="61" r="5"></circle>
                    <circle id="Oval-Copy-102" fill="#94AEFB" cx="53" cy="61" r="5"></circle>
                    <circle id="Oval-Copy-125" fill="#0244FB" cx="29" cy="78" r="5"></circle>
                    <circle id="Oval-Copy-124" fill="#94AEFB" cx="53" cy="78" r="5"></circle>
                    <circle id="Oval-Copy-147" fill="#94AEFB" cx="29" cy="95" r="5"></circle>
                    <circle id="Oval-Copy-146" fill="#94AEFB" cx="53" cy="95" r="5"></circle>
                    <circle id="Oval-Copy-169" fill="#94AEFB" cx="29" cy="112" r="5"></circle>
                    <circle id="Oval-Copy-168" fill="#94AEFB" cx="53" cy="112" r="5"></circle>
                    <circle id="Oval-Copy-191" fill="#94AEFB" cx="29" cy="129" r="5"></circle>
                    <circle id="Oval-Copy-190" fill="#94AEFB" cx="53" cy="129" r="5"></circle>
                    <circle id="Oval-Copy-213" fill="#94AEFB" cx="29" cy="146" r="5"></circle>
                    <circle id="Oval-Copy-212" fill="#0244FB" cx="53" cy="146" r="5"></circle>
                    <circle id="Oval-Copy-235" fill="#0244FB" cx="29" cy="163" r="5"></circle>
                    <circle id="Oval-Copy-234" fill="#0244FB" cx="53" cy="163" r="5"></circle>
                    <circle id="Oval-Copy-257" fill="#0244FB" cx="29" cy="180" r="5"></circle>
                    <circle id="Oval-Copy-256" fill="#0244FB" cx="53" cy="180" r="5"></circle>
                    <circle id="Oval-Copy-279" fill="#0244FB" cx="29" cy="197" r="5"></circle>
                    <circle id="Oval-Copy-278" fill="#0244FB" cx="53" cy="197" r="5"></circle>
                    <circle id="Oval-Copy-301" fill="#94AEFB" cx="29" cy="214" r="5"></circle>
                    <circle id="Oval-Copy-300" fill="#94AEFB" cx="53" cy="214" r="5"></circle>
                    <circle id="Oval-Copy-323" fill="#94AEFB" cx="29" cy="231" r="5"></circle>
                    <circle id="Oval-Copy-322" fill="#94AEFB" cx="53" cy="231" r="5"></circle>
                    <circle id="Oval-Copy-345" fill="#94AEFB" cx="29" cy="248" r="5"></circle>
                    <circle id="Oval-Copy-344" fill="#94AEFB" cx="53" cy="248" r="5"></circle>
                    <circle id="Oval-Copy-367" fill="#94AEFB" cx="29" cy="265" r="5"></circle>
                    <circle id="Oval-Copy-389" fill="#94AEFB" cx="29" cy="282" r="5"></circle>
                    <circle id="Oval-Copy-388" fill="#94AEFB" cx="53" cy="282" r="5"></circle>
                </g>
                <g id="5" transform="translate(175.000000, 32.000000)">
                    <circle id="Oval-Copy-8" fill="#94AEFB" cx="5" cy="5" r="5"></circle>
                    <circle id="Oval-Copy-30" fill="#94AEFB" cx="5" cy="20" r="5"></circle>
                    <circle id="Oval-Copy-9" fill="#0244FB" cx="29" cy="5" r="5"></circle>
                    <circle id="Oval-Copy-31" fill="#94AEFB" cx="29" cy="20" r="5"></circle>
                    <circle id="Oval-Copy-10" fill="#94AEFB" cx="53" cy="5" r="5"></circle>
                    <circle id="Oval-Copy-32" fill="#94AEFB" cx="53" cy="20" r="5"></circle>
                    <circle id="Oval-Copy-11" fill="#94AEFB" cx="58" cy="6" r="5"></circle>
                    <circle id="Oval-Copy-33" fill="#94AEFB" cx="77" cy="20" r="5"></circle>
                    <circle id="Oval-Copy-57" fill="#94AEFB" cx="5" cy="37" r="5"></circle>
                    <circle id="Oval-Copy-56" fill="#94AEFB" cx="29" cy="37" r="5"></circle>
                    <circle id="Oval-Copy-55" fill="#94AEFB" cx="53" cy="37" r="5"></circle>
                    <circle id="Oval-Copy-54" fill="#94AEFB" cx="77" cy="37" r="5"></circle>
                    <circle id="Oval-Copy-79" fill="#94AEFB" cx="5" cy="54" r="5"></circle>
                    <circle id="Oval-Copy-78" fill="#94AEFB" cx="29" cy="54" r="5"></circle>
                    <circle id="Oval-Copy-77" fill="#94AEFB" cx="53" cy="54" r="5"></circle>
                    <circle id="Oval-Copy-76" fill="#94AEFB" cx="77" cy="54" r="5"></circle>
                    <circle id="Oval-Copy-101" fill="#94AEFB" cx="5" cy="71" r="5"></circle>
                    <circle id="Oval-Copy-98" fill="#94AEFB" cx="77" cy="71" r="5"></circle>
                    <circle id="Oval-Copy-123" fill="#94AEFB" cx="5" cy="88" r="5"></circle>
                    <circle id="Oval-Copy-122" fill="#94AEFB" cx="29" cy="88" r="5"></circle>
                    <circle id="Oval-Copy-121" fill="#94AEFB" cx="53" cy="88" r="5"></circle>
                    <circle id="Oval-Copy-120" fill="#94AEFB" cx="77" cy="88" r="5"></circle>
                    <circle id="Oval-Copy-145" fill="#94AEFB" cx="5" cy="105" r="5"></circle>
                    <circle id="Oval-Copy-144" fill="#94AEFB" cx="29" cy="105" r="5"></circle>
                    <circle id="Oval-Copy-143" fill="#0244FB" cx="53" cy="105" r="5"></circle>
                    <circle id="Oval-Copy-142" fill="#94AEFB" cx="77" cy="105" r="5"></circle>
                    <circle id="Oval-Copy-167" fill="#94AEFB" cx="5" cy="122" r="5"></circle>
                    <circle id="Oval-Copy-166" fill="#0244FB" cx="29" cy="122" r="5"></circle>
                    <circle id="Oval-Copy-165" fill="#0244FB" cx="53" cy="122" r="5"></circle>
                    <circle id="Oval-Copy-164" fill="#94AEFB" cx="77" cy="122" r="5"></circle>
                    <circle id="Oval-Copy-189" fill="#0244FB" cx="5" cy="139" r="5"></circle>
                    <circle id="Oval-Copy-188" fill="#0244FB" cx="29" cy="139" r="5"></circle>
                    <circle id="Oval-Copy-187" fill="#0244FB" cx="53" cy="139" r="5"></circle>
                    <circle id="Oval-Copy-186" fill="#94AEFB" cx="77" cy="139" r="5"></circle>
                    <circle id="Oval-Copy-211" fill="#0244FB" cx="5" cy="156" r="5"></circle>
                    <circle id="Oval-Copy-210" fill="#0244FB" cx="29" cy="156" r="5"></circle>
                    <circle id="Oval-Copy-209" fill="#94AEFB" cx="53" cy="156" r="5"></circle>
                    <circle id="Oval-Copy-208" fill="#94AEFB" cx="77" cy="156" r="5"></circle>
                    <circle id="Oval-Copy-233" fill="#0244FB" cx="5" cy="173" r="5"></circle>
                    <circle id="Oval-Copy-232" fill="#0244FB" cx="29" cy="173" r="5"></circle>
                    <circle id="Oval-Copy-231" fill="#94AEFB" cx="53" cy="173" r="5"></circle>
                    <circle id="Oval-Copy-230" fill="#94AEFB" cx="77" cy="173" r="5"></circle>
                    <circle id="Oval-Copy-262" fill="#94AEFB" cx="16" cy="181" r="3"></circle>
                    <circle id="Oval-Copy-255" fill="#0244FB" cx="5" cy="190" r="5"></circle>
                    <circle id="Oval-Copy-254" fill="#94AEFB" cx="29" cy="190" r="5"></circle>
                    <circle id="Oval-Copy-253" fill="#94AEFB" cx="53" cy="190" r="5"></circle>
                    <circle id="Oval-Copy-252" fill="#94AEFB" cx="77" cy="190" r="5"></circle>
                    <circle id="Oval-Copy-277" fill="#94AEFB" cx="5" cy="207" r="5"></circle>
                    <circle id="Oval-Copy-276" fill="#94AEFB" cx="29" cy="207" r="5"></circle>
                    <circle id="Oval-Copy-275" fill="#94AEFB" cx="53" cy="207" r="5"></circle>
                    <circle id="Oval-Copy-274" fill="#94AEFB" cx="77" cy="207" r="5"></circle>
                    <circle id="Oval-Copy-299" fill="#94AEFB" cx="5" cy="224" r="5"></circle>
                    <circle id="Oval-Copy-298" fill="#94AEFB" cx="29" cy="224" r="5"></circle>
                    <circle id="Oval-Copy-297" fill="#94AEFB" cx="53" cy="224" r="5"></circle>
                    <circle id="Oval-Copy-296" fill="#94AEFB" cx="77" cy="224" r="5"></circle>
                    <circle id="Oval-Copy-321" fill="#94AEFB" cx="5" cy="241" r="5"></circle>
                    <circle id="Oval-Copy-320" fill="#94AEFB" cx="29" cy="241" r="5"></circle>
                    <circle id="Oval-Copy-319" fill="#94AEFB" cx="53" cy="241" r="5"></circle>
                    <circle id="Oval-Copy-318" fill="#94AEFB" cx="77" cy="241" r="5"></circle>
                    <circle id="Oval-Copy-343" fill="#94AEFB" cx="5" cy="258" r="5"></circle>
                    <circle id="Oval-Copy-342" fill="#94AEFB" cx="29" cy="258" r="5"></circle>
                    <circle id="Oval-Copy-341" fill="#94AEFB" cx="53" cy="258" r="5"></circle>
                    <circle id="Oval-Copy-340" fill="#94AEFB" cx="77" cy="258" r="5"></circle>
                    <circle id="Oval-Copy-364" fill="#94AEFB" cx="29" cy="275" r="5"></circle>
                    <circle id="Oval-Copy-363" fill="#94AEFB" cx="53" cy="275" r="5"></circle>
                    <circle id="Oval-Copy-362" fill="#94AEFB" cx="77" cy="275" r="5"></circle>
                    <circle id="Oval-Copy-387" fill="#94AEFB" cx="5" cy="292" r="5"></circle>
                    <circle id="Oval-Copy-386" fill="#94AEFB" cx="29" cy="292" r="5"></circle>
                    <circle id="Oval-Copy-385" fill="#94AEFB" cx="53" cy="292" r="5"></circle>
                    <circle id="Oval-Copy-384" fill="#94AEFB" cx="77" cy="292" r="5"></circle>
                </g>
                <g id="6" transform="translate(254.000000, 47.000000)">
                    <circle id="Oval-Copy-12" fill="#94AEFB" cx="12" cy="15" r="3"></circle>
                    <circle id="Oval-Copy-34" fill="#94AEFB" cx="12" cy="5" r="5"></circle>
                    <circle id="Oval-Copy-35" fill="#94AEFB" cx="58" cy="61" r="3"></circle>
                    <circle id="Oval-Copy-53" fill="#94AEFB" cx="31" cy="22" r="5"></circle>
                    <circle id="Oval-Copy-52" fill="#94AEFB" cx="36" cy="27" r="5"></circle>
                    <circle id="Oval-Copy-75" fill="#94AEFB" cx="22" cy="39" r="5"></circle>
                    <circle id="Oval-Copy-74" fill="#94AEFB" cx="46" cy="39" r="5"></circle>
                    <circle id="Oval-Copy-97" fill="#94AEFB" cx="22" cy="56" r="5"></circle>
                    <circle id="Oval-Copy-96" fill="#94AEFB" cx="46" cy="56" r="5"></circle>
                    <circle id="Oval-Copy-95" fill="#94AEFB" cx="70" cy="56" r="5"></circle>
                    <circle id="Oval-Copy-94" fill="#94AEFB" cx="89" cy="61" r="5"></circle>
                    <circle id="Oval-Copy-119" fill="#94AEFB" cx="22" cy="73" r="5"></circle>
                    <circle id="Oval-Copy-118" fill="#94AEFB" cx="46" cy="73" r="5"></circle>
                    <circle id="Oval-Copy-117" fill="#94AEFB" cx="70" cy="73" r="5"></circle>
                    <circle id="Oval-Copy-116" fill="#94AEFB" cx="94" cy="73" r="5"></circle>
                    <circle id="Oval-Copy-141" fill="#94AEFB" cx="22" cy="90" r="5"></circle>
                    <circle id="Oval-Copy-140" fill="#94AEFB" cx="46" cy="90" r="5"></circle>
                    <circle id="Oval-Copy-139" fill="#94AEFB" cx="70" cy="90" r="5"></circle>
                    <circle id="Oval-Copy-138" fill="#6261D4" cx="94" cy="90" r="5"></circle>
                    <circle id="Oval-Copy-163" fill="#94AEFB" cx="22" cy="107" r="5"></circle>
                    <circle id="Oval-Copy-162" fill="#94AEFB" cx="46" cy="107" r="5"></circle>
                    <circle id="Oval-Copy-161" fill="#6261D4" cx="70" cy="107" r="5"></circle>
                    <circle id="Oval-Copy-160" fill="#6261D4" cx="94" cy="107" r="5"></circle>
                    <circle id="Oval-Copy-185" fill="#94AEFB" cx="22" cy="124" r="5"></circle>
                    <circle id="Oval-Copy-184" fill="#6261D4" cx="46" cy="124" r="5"></circle>
                    <circle id="Oval-Copy-183" fill="#6261D4" cx="70" cy="124" r="5"></circle>
                    <circle id="Oval-Copy-182" fill="#6261D4" cx="94" cy="124" r="5"></circle>
                    <circle id="Oval-Copy-207" fill="#94AEFB" cx="22" cy="141" r="5"></circle>
                    <circle id="Oval-Copy-206" fill="#6261D4" cx="46" cy="141" r="5"></circle>
                    <circle id="Oval-Copy-205" fill="#6261D4" cx="70" cy="141" r="5"></circle>
                    <circle id="Oval-Copy-204" fill="#94AEFB" cx="94" cy="141" r="5"></circle>
                    <circle id="Oval-Copy-229" fill="#94AEFB" cx="22" cy="158" r="5"></circle>
                    <circle id="Oval-Copy-228" fill="#6261D4" cx="46" cy="158" r="5"></circle>
                    <circle id="Oval-Copy-227" fill="#94AEFB" cx="70" cy="158" r="5"></circle>
                    <circle id="Oval-Copy-226" fill="#94AEFB" cx="94" cy="158" r="5"></circle>
                    <circle id="Oval-Copy-263" fill="#94AEFB" cx="3" cy="206" r="3"></circle>
                    <circle id="Oval-Copy-284" fill="#94AEFB" cx="27" cy="78" r="3"></circle>
                    <circle id="Oval-Copy-251" fill="#94AEFB" cx="22" cy="175" r="5"></circle>
                    <circle id="Oval-Copy-250" fill="#94AEFB" cx="46" cy="175" r="5"></circle>
                    <circle id="Oval-Copy-249" fill="#94AEFB" cx="70" cy="175" r="5"></circle>
                    <circle id="Oval-Copy-248" fill="#94AEFB" cx="94" cy="175" r="5"></circle>
                    <circle id="Oval-Copy-273" fill="#94AEFB" cx="22" cy="192" r="5"></circle>
                    <circle id="Oval-Copy-272" fill="#94AEFB" cx="46" cy="192" r="5"></circle>
                    <circle id="Oval-Copy-270" fill="#94AEFB" cx="94" cy="192" r="5"></circle>
                    <circle id="Oval-Copy-271" fill="#94AEFB" cx="70" cy="192" r="5"></circle>
                    <circle id="Oval-Copy-285" fill="#94AEFB" cx="70" cy="208" r="5"></circle>
                    <circle id="Oval-Copy-295" fill="#94AEFB" cx="22" cy="209" r="5"></circle>
                    <circle id="Oval-Copy-294" fill="#94AEFB" cx="46" cy="209" r="5"></circle>
                    <circle id="Oval-Copy-292" fill="#94AEFB" cx="94" cy="209" r="5"></circle>
                    <circle id="Oval-Copy-317" fill="#94AEFB" cx="22" cy="226" r="5"></circle>
                    <circle id="Oval-Copy-316" fill="#94AEFB" cx="46" cy="226" r="5"></circle>
                    <circle id="Oval-Copy-315" fill="#94AEFB" cx="70" cy="226" r="5"></circle>
                    <circle id="Oval-Copy-314" fill="#94AEFB" cx="94" cy="226" r="5"></circle>
                    <circle id="Oval-Copy-339" fill="#94AEFB" cx="22" cy="243" r="5"></circle>
                    <circle id="Oval-Copy-338" fill="#94AEFB" cx="46" cy="243" r="5"></circle>
                    <circle id="Oval-Copy-337" fill="#94AEFB" cx="70" cy="243" r="5"></circle>
                    <circle id="Oval-Copy-336" fill="#94AEFB" cx="94" cy="243" r="5"></circle>
                    <circle id="Oval-Copy-361" fill="#94AEFB" cx="22" cy="260" r="5"></circle>
                    <circle id="Oval-Copy-360" fill="#94AEFB" cx="46" cy="260" r="5"></circle>
                    <circle id="Oval-Copy-359" fill="#94AEFB" cx="70" cy="260" r="5"></circle>
                    <circle id="Oval-Copy-358" fill="#94AEFB" cx="94" cy="260" r="5"></circle>
                    <circle id="Oval-Copy-383" fill="#94AEFB" cx="22" cy="277" r="5"></circle>
                    <circle id="Oval-Copy-382" fill="#94AEFB" cx="46" cy="277" r="5"></circle>
                    <circle id="Oval-Copy-381" fill="#94AEFB" cx="70" cy="277" r="5"></circle>
                    <circle id="Oval-Copy-380" fill="#94AEFB" cx="94" cy="277" r="5"></circle>
                </g>
                <g id="7" transform="translate(367.000000, 108.000000)">
                    <circle id="Oval-Copy-37" fill="#94AEFB" cx="36" cy="3" r="3"></circle>
                    <circle id="Oval-Copy-38" fill="#94AEFB" cx="45" cy="3" r="3"></circle>
                    <circle id="Oval-Copy-93" fill="#94AEFB" cx="19" cy="29" r="5"></circle>
                    <circle id="Oval-Copy-115" fill="#94AEFB" cx="5" cy="12" r="5"></circle>
                    <circle id="Oval-Copy-137" fill="#94AEFB" cx="5" cy="29" r="5"></circle>
                    <circle id="Oval-Copy-159" fill="#94AEFB" cx="5" cy="46" r="5"></circle>
                    <circle id="Oval-Copy-158" fill="#94AEFB" cx="29" cy="46" r="5"></circle>
                    <circle id="Oval-Copy-157" fill="#94AEFB" cx="53" cy="46" r="5"></circle>
                    <circle id="Oval-Copy-156" fill="#0244FB" cx="77" cy="56" r="5"></circle>
                    <circle id="Oval-Copy-181" fill="#94AEFB" cx="5" cy="63" r="5"></circle>
                    <circle id="Oval-Copy-180" fill="#94AEFB" cx="29" cy="63" r="5"></circle>
                    <circle id="Oval-Copy-179" fill="#94AEFB" cx="53" cy="63" r="5"></circle>
                    <circle id="Oval-Copy-178" fill="#0244FB" cx="77" cy="63" r="5"></circle>
                    <circle id="Oval-Copy-203" fill="#94AEFB" cx="5" cy="80" r="5"></circle>
                    <circle id="Oval-Copy-202" fill="#94AEFB" cx="29" cy="80" r="5"></circle>
                    <circle id="Oval-Copy-201" fill="#94AEFB" cx="53" cy="80" r="5"></circle>
                    <circle id="Oval-Copy-200" fill="#94AEFB" cx="77" cy="80" r="5"></circle>
                    <circle id="Oval-Copy-225" fill="#94AEFB" cx="5" cy="97" r="5"></circle>
                    <circle id="Oval-Copy-224" fill="#94AEFB" cx="29" cy="97" r="5"></circle>
                    <circle id="Oval-Copy-223" fill="#94AEFB" cx="53" cy="97" r="5"></circle>
                    <circle id="Oval-Copy-222" fill="#94AEFB" cx="77" cy="97" r="5"></circle>
                    <circle id="Oval-Copy-247" fill="#94AEFB" cx="5" cy="114" r="5"></circle>
                    <circle id="Oval-Copy-246" fill="#94AEFB" cx="29" cy="114" r="5"></circle>
                    <circle id="Oval-Copy-245" fill="#94AEFB" cx="53" cy="114" r="5"></circle>
                    <circle id="Oval-Copy-244" fill="#94AEFB" cx="77" cy="114" r="5"></circle>
                    <circle id="Oval-Copy-269" fill="#94AEFB" cx="5" cy="131" r="5"></circle>
                    <circle id="Oval-Copy-268" fill="#94AEFB" cx="29" cy="131" r="5"></circle>
                    <circle id="Oval-Copy-267" fill="#94AEFB" cx="53" cy="131" r="5"></circle>
                    <circle id="Oval-Copy-266" fill="#94AEFB" cx="77" cy="131" r="5"></circle>
                    <circle id="Oval-Copy-291" fill="#94AEFB" cx="5" cy="148" r="5"></circle>
                    <circle id="Oval-Copy-290" fill="#94AEFB" cx="29" cy="148" r="5"></circle>
                    <circle id="Oval-Copy-289" fill="#94AEFB" cx="53" cy="148" r="5"></circle>
                    <circle id="Oval-Copy-288" fill="#94AEFB" cx="77" cy="148" r="5"></circle>
                    <circle id="Oval-Copy-313" fill="#94AEFB" cx="5" cy="165" r="5"></circle>
                    <circle id="Oval-Copy-312" fill="#94AEFB" cx="29" cy="165" r="5"></circle>
                    <circle id="Oval-Copy-311" fill="#94AEFB" cx="53" cy="165" r="5"></circle>
                    <circle id="Oval-Copy-310" fill="#94AEFB" cx="77" cy="165" r="5"></circle>
                    <circle id="Oval-Copy-335" fill="#94AEFB" cx="5" cy="182" r="5"></circle>
                    <circle id="Oval-Copy-334" fill="#94AEFB" cx="29" cy="182" r="5"></circle>
                    <circle id="Oval-Copy-333" fill="#94AEFB" cx="53" cy="182" r="5"></circle>
                    <circle id="Oval-Copy-332" fill="#94AEFB" cx="77" cy="182" r="5"></circle>
                    <circle id="Oval-Copy-357" fill="#94AEFB" cx="5" cy="199" r="5"></circle>
                    <circle id="Oval-Copy-356" fill="#94AEFB" cx="29" cy="199" r="5"></circle>
                    <circle id="Oval-Copy-355" fill="#94AEFB" cx="53" cy="199" r="5"></circle>
                    <circle id="Oval-Copy-354" fill="#94AEFB" cx="77" cy="199" r="5"></circle>
                    <circle id="Oval-Copy-379" fill="#94AEFB" cx="5" cy="216" r="5"></circle>
                    <circle id="Oval-Copy-392" fill="#94AEFB" cx="14" cy="233" r="5"></circle>
                    <circle id="Oval-Copy-394" fill="#94AEFB" cx="48" cy="233" r="5"></circle>
                    <circle id="Oval-Copy-378" fill="#94AEFB" cx="31" cy="216" r="5"></circle>
                    <circle id="Oval-Copy-377" fill="#94AEFB" cx="53" cy="214" r="5"></circle>
                    <circle id="Oval-Copy-376" fill="#94AEFB" cx="77" cy="206" r="5"></circle>
                </g>
                <g id="8" transform="translate(458.000000, 166.000000)" fill="#94AEFB">
                    <circle id="Oval-Copy-155" cx="24" cy="22" r="5"></circle>
                    <circle id="Oval-Copy-177" cx="10" cy="5" r="5"></circle>
                    <circle id="Oval-Copy-199" cx="10" cy="22" r="5"></circle>
                    <circle id="Oval-Copy-221" cx="10" cy="39" r="5"></circle>
                    <circle id="Oval-Copy-220" cx="27" cy="39" r="5"></circle>
                    <circle id="Oval-Copy-243" cx="10" cy="56" r="5"></circle>
                    <circle id="Oval-Copy-242" cx="32" cy="56" r="5"></circle>
                    <circle id="Oval-Copy-265" cx="10" cy="73" r="5"></circle>
                    <circle id="Oval-Copy-264" cx="32" cy="72" r="5"></circle>
                    <circle id="Oval-Copy-287" cx="10" cy="90" r="5"></circle>
                    <circle id="Oval-Copy-286" cx="32" cy="89" r="5"></circle>
                    <circle id="Oval-Copy-309" cx="10" cy="107" r="5"></circle>
                    <circle id="Oval-Copy-308" cx="29" cy="102" r="5"></circle>
                    <circle id="Oval-Copy-331" cx="16" cy="123" r="5"></circle>
                    <circle id="Oval-Copy-330" cx="20" cy="118" r="5"></circle>
                    <circle id="Oval-Copy-353" cx="5" cy="139" r="5"></circle>
                </g>
                <g id="rest" fill="#94AEFB">
                    <circle id="Oval-Copy-36" cx="367" cy="56" r="3"></circle>
                    <circle id="Oval-Copy-61" cx="151" cy="5" r="5"></circle>
                    <circle id="Oval-Copy-62" cx="196" cy="10" r="5"></circle>
                    <circle id="Oval-Copy-63" cx="5" cy="159" r="5"></circle>
                    <circle id="Oval-Copy-64" cx="22" cy="147" r="5"></circle>
                </g>
            </g>
        </g>
    </g>
</svg>)